export default function HeroSection() {
    return (
      <section id="heroSection" className="hero--section">
        <div className="hero--section--content--box">
          <div className="hero--section--content">
            <p className="section--title">Olá, eu sou o Allan!</p>
            <h1 className="hero--section--title">
              <span className="hero--section-title--color">Back-end</span>{" "}
              <br />
              Developer
            </h1>
            <p className="hero--section-description">
             Seja bem-vindo(a) ao meu Portfolio.
            </p>
          </div>
          {/* <button  className="btn btn-primary"> Me contrate</button> */}
        </div>
        <div className="hero--section--img">
          <img src="./img/hero_img.png" style={{ width: '400px', height: 'auto' }} alt="Hero Section" />
        </div>
      </section>
    );
  }
  