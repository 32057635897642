export default function AboutMe() {
  return (
    <section id="AboutMe" className="about--section">
      <div className="about--section--img">
        {/* <img src="./img/about-me.png" style={{ width: '400px', height: 'auto' }} alt="About Me" /> */}
      </div>
      <div className="hero--section--content--box about--section--box">
        <div className="hero--section--content">
          <p className="section--title">Um pouquinho</p>
          <h1 className="skills-section--heading">Sobre mim</h1>
          <p className="hero--section-description">
            Deslumbrado por aquilo que o usuário não vê, desde os 13 anos, onde comecei
            a mexer com querys e servidores para montar meu joguinho online (Mu online) com
            vários arquivos opensource disponíveis nos foruns "gringos" na época. Além disso,
            aficionado por tecnologia (harwares e etc...) e mecânica, onde surgiu a alguns anos
            a oportunidade de estudar Engenharia Mecânica com bolsa, porém, o lado TEC
            gritou mais alto, e cá estamos.
          </p>
          <p className="hero--section-description">
            Com um propósito e muito motivado tenho certeza que posso agregar a qualquer
            equipe que me aceitar junto dela. Desafio para mim é sinônimo de aprendizado
            e creio que nessa área é o que buscamos.
          </p>
        </div>
      </div>
    </section>
  );
}
