import React from 'react';

export default function ContactMe() {
  const handleEmailClick = () => {
    const email = 'allan.minato@gmail.com';
    const subject = 'Allan, tenho uma proposta para você!';
    const body = ''; 

    const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
    
    window.location.href = mailtoLink;
  };

  return (
    <section id="Contact" className="contact--section">
      <div>
        <p className="sub--title">Se interessou?</p>
        <h2>Entre em contato</h2>
        <p className="text-lg">
          Qualquer dúvida, por favor, entrar em contato por qualquer plataforma disponível.
        </p>
      </div>
      <button className="btn btn-primary contact--form--btn" onClick={handleEmailClick}>
        Enviar E-mail
      </button>
    </section>
  );
}
